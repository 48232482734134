export const Contactdata = [
  {
    type: "phone",
    icon: "feather-phone-call",
    title: "Phone Number",
    link: "tel:+ 41 78 239 68 50",
    text: "+ 41 78 239 68 50",
  },
  {
    type: "email",
    icon: "feather-mail",
    title: "Email Address",
    link: "mailto:info@eleego.ch",
    text: "info@eleego.ch",
  },
  {
    type: "hours",
    icon: "feather-clock",
    title: "Opening Hours",
    link: "#;",
    text: "Always Open",
  },
];
