import React, { useEffect } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Breadcrumbs from "../common/Breadcrumbs";
import Aos from "aos";
import { Link } from "react-router-dom";
import { testimonialsData } from "../../core/data/json/testimonials_data";

const AboutUs = () => {
  const testimonials = testimonialsData;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);
  return (
    <>
      {/* Breadscrumb Section */}
      <Breadcrumbs title="About Us" subtitle="About Us" />
      {/* /Breadscrumb Section */}
      {/* About */}
      <section className="section about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6" data-aos="fade-down">
              <div className="about-img">
                <div className="about-exp">
                  <span>Many years of experiences</span>
                </div>
                <div className="abt-img">
                  <ImageWithBasePath
                    src="assets/img/AboutUs.jpg"
                    className="img-fluid"
                    alt="About us"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-down">
              <div className="about-content">
                <h6>ABOUT OUR COMPANY</h6>
                <h2>Your trusted partner in luxury travel</h2>
                <p>
                  Welcome to Eleego Limousine and Rental Service, where luxury
                  meets convenience. Located in Geneva, Switzerland, and
                  established in 2023, our company has quickly become a premier
                  choice for customers seeking top-quality transportation
                  solutions at an affordable rate.
                </p>
                <p>
                  At Eleego, we are dedicated to giving our customers a luxury,
                  convenient, and unforgettable transportation experience by
                  ensuring our guests make memories that will last for a
                  lifetime. We believe in going the extra mile to make your
                  experience with us unforgettable, whether its arranging
                  special amenities for your trip or providing expert
                  recommendations to enhance your tour.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul>
                      <li>Customer-Centric Service</li>
                      <li>Wide Range of Vehicles</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li>Affordable Rates</li>
                      <li>Convenient Booking and Support</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /About */}
      {/* services */}
      <section className="section services">
        <div className="service-right">
          <ImageWithBasePath
            src="assets/img/bg/service-right.svg"
            className="img-fluid"
            alt="services right"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>The booking process</h2>
            <p>Booking a car with us is quick and easy</p>
          </div>
          {/* /Heading title */}
          <div className="services-work">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-secondary">
                    <ImageWithBasePath
                      className="icon-img bg-secondary"
                      src="assets/img/icons/services-icon-01.svg"
                      alt="Choose Locations"
                    />
                  </div>
                  <div className="services-content">
                    <h3>1. Choose a vehicle</h3>
                    <p>
                      Browse our selection of cars and pick the one that suits
                      your needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-warning">
                    <ImageWithBasePath
                      className="icon-img bg-warning"
                      src="assets/img/icons/services-icon-02.svg"
                      alt="Choose Locations"
                    />
                  </div>
                  <div className="services-content">
                    <h3>2. Validate details</h3>
                    <p>
                      Enter your personal and rental details to confirm
                      everything is correct.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-dark">
                    <ImageWithBasePath
                      className="icon-img bg-dark"
                      src="assets/img/icons/services-icon-03.svg"
                      alt="Choose Locations"
                    />
                  </div>
                  <div className="services-content">
                    <h3>3. Book your vehicle</h3>
                    <p>
                      Complete your booking and get ready to enjoy your ride!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /services */}

      {/* Why Choose Us */}
      <section className="section why-choose">
        <div className="choose-left">
          <ImageWithBasePath
            src="assets/img/bg/choose-left.png"
            className="img-fluid"
            alt="Why Choose Us"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Why Choose Us</h2>
            <p>
              With Reliable cars, great prices, and top-notch service your
              perfect ride awaits!
            </p>
          </div>
          {/* /Heading title */}
          <div className="why-choose-group">
            <div className="row">
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-black">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-user-check.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Easy Booking Process</h4>
                      <p>
                        Our simple and straightforward booking process allows
                        you to reserve your car quickly and efficiently, saving
                        you time and effort.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-secondary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-user-check.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Flexible Rental Options</h4>
                      <p>
                        Whether you need a car for a day, a week, or longer, we
                        offer flexible rental periods to suit your schedule.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-primary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-user-check.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Excellent Customer Service</h4>
                      <p>
                        Our friendly and knowledgeable team is here to assist
                        you every step of the way, ensuring a smooth and
                        hassle-free rental experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Why Choose Us */}
      {/* About us Testimonials */}
      <section className="section about-testimonial testimonials-section">
        <div className="container">
          <div className="section-heading" data-aos="fade-down">
            <h2 className="title text-white">Customer Testimonials</h2>
            <p className="description text-white">
              Discover why our customers love us! Read what others have to say
              about their experiences with our car rental services.
            </p>
          </div>
          <div className="owl-carousel about-testimonials testimonial-group mb-0 owl-theme">
            <Slider {...settings}>
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="testimonial-item d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <div className="quotes-head" />
                      <div className="review-box">
                        <div className="review-details">
                          <h6>{testimonial.name}</h6>
                        </div>
                      </div>
                      <p>{testimonial.review}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      {/* About us Testimonials */}
      <>
        {/* FAQ  */}
        <section className="section faq-section bg-light-primary">
          <div className="container">
            {/* Heading title*/}
            <div className="section-heading" data-aos="fade-down">
              <h2>Frequently Asked Questions </h2>
              <p>
                Find answers to common questions about our car rental services,
                policies, and more to make your experience with us seamless and
                straightforward.
              </p>
            </div>
            {/* /Heading title */}
            <div className="faq-info">
              <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                  <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    to="#faqOne"
                    aria-expanded="false"
                  >
                    What is about eleego?
                  </Link>
                </h4>
                <div id="faqOne" className="card-collapse collapse">
                  <p>
                    Located in Geneva, Switzerland, and established in 2023, our
                    company has quickly become a premier choice for customers
                    seeking top-quality transportation solutions at an
                    affordable rate. At Eleego, we are dedicated to giving our
                    customers a luxury, convenient, and unforgettable
                    transportation experience by ensuring our guests make
                    memories that will last for a lifetime.
                  </p>
                </div>
              </div>
              <div className="faq-card bg-white" data-aos="fade-down">
                <h4 className="faq-title">
                  <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    to="#faqTwo"
                    aria-expanded="false"
                  >
                    In which areas do you operate?
                  </Link>
                </h4>
                <div id="faqTwo" className="card-collapse collapse">
                  <p>
                    At Eleego Limousine and Rental Service, we offer a range of
                    premium transportation solutions tailored to meet your
                    needs:
                  </p>
                  <p>
                    <b>Limousine Services:</b> Our luxurious limousine services
                    are perfect for special occasions, business trips, airport
                    transfers, and city tours. Enjoy the comfort and elegance of
                    our well-maintained fleet, driven by professional chauffeurs
                    dedicated to providing you with a first-class experience
                  </p>
                  <p>
                    <b>Car Rentals:</b> For those who prefer to drive
                    themselves, we offer a selection of high-quality rental
                    cars. From sleek sedans to spacious SUVs, our rental fleet
                    is designed to cater to your specific travel requirements.
                    Whether you&apos;re exploring Geneva or embarking on a
                    longer journey, our rental cars provide the freedom and
                    flexibility you need.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /FAQ */}
      </>
    </>
  );
};

export default AboutUs;
