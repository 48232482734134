import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { all_routes } from "../router/all_routes";
import { Mail, PhoneCall } from "react-feather";

const Footer = () => {

  const route = all_routes
  return (
    <>
      {/* Footer */}
      <div className="footer">
        {/* Footer Top */}
        <div className="footer-top aos" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">About Company</h5>
                      <ul>
                        <li>
                          <Link to={route.home}>Our Company</Link>
                        </li>
                        <li>
                          <Link to={route.home}>About Us</Link>
                        </li>
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  <div className="col-lg-4 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">Vehicles Type</h5>
                      <ul>
                        <li>
                          <Link to={`${route.listinggrid}?category=rental`}>Cars</Link>
                        </li>
                        <li>
                          <Link to={`${route.listinggrid}?category=limousine`}>Limousine</Link>
                        </li>
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  {/* <div className="col-lg-4 col-md-6">
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">Quick links</h5>
                      <ul>
                        <li>
                          <Link to="#">Sign In</Link>
                        </li>
                        <li>
                          <Link to="#">Sign Up</Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="footer-contact footer-widget">
                  <h5 className="footer-title">Contact Info</h5>
                  <div className="footer-contact-info">
                    <div className="footer-address">
                      <span>
                        <PhoneCall />

                      </span>
                      <div className="addr-info">
                        <Link to="tel:+1(888)7601940">+ 41 78 239 68 50</Link>
                      </div>
                    </div>
                    <div className="footer-address">
                      <span>
                      <Mail/>
                      </span>
                      <div className="addr-info">
                        <Link to="mailto:info@eleego.ch">
                          info@eleego.ch
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="footer-social-widget">
                    <h6>Connect with us</h6>
                    <ul className="nav-social">
                      <li>
                        <Link to="https://www.facebook.com/profile.php?id=61553772887408&mibextid=LQQJ4d">
                          <i className="fa-brands fa-facebook-f fa-facebook fi-icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/eleego?igsh=MWhhanB3MHh3a2Mxag==">
                          <i className="fab fa-instagram fi-icon" />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#">
                          <i className="fab fa-linkedin fi-icon" />
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Footer Top */}
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="copyright-text">
                    <p>© 2024 Eleego. All Rights Reserved.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Copyright Menu */}
                  <div className="copyright-menu">
                    <div className="vistors-details">
                      <ul className="d-flex">
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/paypal.svg"
                              alt="Paypal"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/visa.svg"
                              alt="Visa"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/master.svg"
                              alt="Master"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/applegpay.svg"
                              alt="applegpay"
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* /Copyright Menu */}
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}
      </div>
      {/* /Footer */}
    </>
  );
};

export default Footer;
