export const testimonialsData = [
    {
      id: 1,
      name: "Sarah L., Lausanne",
      image: "/assets/img/profiles/avatar-02.jpg",
      rating: 5.0,
      review:
        "I recently used Eleego Limousine and Rental Service for an important business meeting, and I couldn't have been more impressed. The limousine was immaculate, and our chauffeur was incredibly professional and courteous. The entire experience added a touch of elegance to our day, and I would highly recommend Eleego to anyone looking for top-notch transportation services in Switzerland.",
    },
    {
      id: 2,
      name: "John M., Montreux",
      image: "/assets/img/profiles/avatar-03.jpg",
      rating: 5.0,
      review:
        "During a recent trip to Montreux, I decided to rent a car from Eleego. The process was smooth and hassle-free, and the vehicle was in pristine condition. The team at Eleego went above and beyond to accommodate my needs, ensuring I had a comfortable and reliable car for my stay. Their exceptional service and attention to detail truly set them apart.",
    },
    {
      id: 3,
      name: "Emma R., Lucerne",
      image: "/assets/img/profiles/avatar-03.jpg",
      rating: 5.0,
      review:
        "Eleego Limousine and Rental Service provided outstanding service during my visit to Lucerne. The car rental process was quick and easy, and the vehicle was spotless and well-maintained. The convenience and comfort of having a reliable car made my trip much more enjoyable. I highly recommend Eleego for anyone in need of quality transportation services in Switzerland.",
    },
    {
      id: 4,
      name: "David K., Interlaken",
      image: "/assets/img/profiles/avatar-03.jpg",
      rating: 5.0,
      review:
        "I booked a limousine from Eleego for a special tour around Interlaken, and it was an amazing experience. The chauffeur was professional and knowledgeable, making the journey smooth and enjoyable. The luxury and comfort of the limousine made the tour truly memorable. Eleego's exceptional service and attention to detail make them the best choice for transportation in Switzerland.",
    },
    // Add more testimonials as needed
  ];