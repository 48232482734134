import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { submitLimousineBooking } from "../../core/data/redux/action";
import { AppDispatch, RootState } from "../../core/data/redux/store";
import { CornerDownLeft } from "react-feather";

interface Props {
  vehicleData: any;
  startDate: string;
  endDate: string;
}

const route = all_routes;

const ContactOwner = ({ vehicleData, startDate, endDate }: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const { loading, booking, error } = useSelector((state: RootState) => state);

  const formRef = useRef<HTMLFormElement>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const initialState = {
    vehicle_id: vehicleData.id,
    pickup_date_time: startDate || "",
    drop_date_time: endDate || "",
    service_type: "One Way",
    pickup_address: "",
    drop_address: "",
    no_of_persons: 1,
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    payment_method: "cash",
    additional_services: [],
    additional_information: "",
    category: vehicleData.category === "Limousine" ? "limousine" : "rental",
    child_count: 1,
    child_ages: "",
    total_amount: 0,
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    const calculateTotalAmount = () => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const days = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
      const totalAmount = days * vehicleData.per_day_amount;
      setFormData((prevFormData) => ({
        ...prevFormData,
        pickup_date_time: startDate,
        drop_date_time: endDate,
        total_amount: totalAmount,
      }));
    };

    calculateTotalAmount();
  }, [startDate, endDate, vehicleData.per_day_amount]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (checked) {
        setFormData({
          ...formData,
          additional_services: [...formData.additional_services, value],
        });
      } else {
        setFormData({
          ...formData,
          additional_services: formData.additional_services.filter(
            (service: any) => service !== value
          ),
        });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  if (vehicleData.category === "Rental") {
    const setPickupAddressToGeneva = () => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        pickup_address: "Geneva Airport",
        drop_address: "Geneva Airport",
      }));
    };

    useEffect(() => {
      setPickupAddressToGeneva();
    }, []);
  }

  const validate = () => {
    const newErrors: any = {};
    if (!formData.pickup_date_time)
      newErrors.pickup_date_time = "Pick-up date & time is required";
    if (!formData.drop_date_time)
      newErrors.drop_date_time = "Drop-off date & time is required";
    if (!formData.pickup_address && vehicleData.category === "Limousine")
      newErrors.pickup_address = "Pick-up address is required";
    if (!formData.drop_address && vehicleData.category === "Limousine")
      newErrors.drop_address = "Drop-off address is required";
    if (!formData.service_type)
      newErrors.service_type = "Service Type is required";
    if (!formData.no_of_persons)
      newErrors.no_of_persons = "Number of persons is required";
    if (!formData.customer_name) newErrors.customer_name = "Name is required";
    if (!formData.customer_email)
      newErrors.customer_email = "Email is required";
    if (!formData.customer_phone)
      newErrors.customer_phone = "Phone number is required";
    if (!formData.payment_method)
      newErrors.payment_method = "Payment method is required";
    if (formData.additional_services.includes("child seats")) {
      if (!formData.child_count)
        newErrors.child_count = "Number of children is required";
      if (!formData.child_ages)
        newErrors.child_ages = "Child ages are required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate()) {
      await dispatch(submitLimousineBooking(formData));

      if (formRef.current) {
        formRef.current.reset();
      }
      setFormData(initialState);
      setShowSuccessMessage(true);

      setTimeout(() => {
        setShowSuccessMessage(false);
        window.location.reload();
      }, 3000); // 3 seconds
    }
  };

  useEffect(() => {
    setShowSuccessMessage(false);
  }, [booking]);

  return (
    <div>
      <div className="main-wrapper login-body">
        <header className="log-header">
          <Link to={route.home}>
            <ImageWithBasePath
              className="img-fluid logo-dark"
              src="assets/img/logo.svg"
              alt="Logo"
            />
          </Link>
        </header>
        <div className="login-wrapper">
          <div className="loginbox">
            <div className="login-auth">
              <div className="login-auth-wrap">
                <div className="sign-group">
                  <button
                    onClick={() => {
                      window.location.href = route.home;
                      window.location.reload();
                    }}
                    className="btn sign-up btn-primary btn-sm my-3"
                  >
                    <span>
                      <CornerDownLeft />
                    </span>{" "}
                    Back To Home
                  </button>
                </div>
                <h1>Booking Details</h1>
                {vehicleData.category === "Limousine" ? (
                  <p className="account-subtitle">
                    Fill out following details to receive a quotation for our
                    premier Limousine Service.
                  </p>
                ) : (
                <>
                  <p className="account-subtitle">
                      Fill out following details to book a vehicle.
                  </p>
                  {/* <div className="total-amount">
                    <h3 className="text-danger">Total Amount: ${formData.total_amount}</h3>
                  </div> */}
                </>
                )}
                <br></br>
                <form ref={formRef} onSubmit={handleSubmit}>
                  {vehicleData.category === "Limousine" ? (
                    <>
                      <div className="input-block">
                        <label className="form-label">
                          Pick-up Date & Time
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          name="pickup_date_time"
                          value={formData.pickup_date_time}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.pickup_date_time && (
                          <span className="text-danger">
                            {errors.pickup_date_time}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Drop-off Date & Time
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          name="drop_date_time"
                          value={formData.drop_date_time}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.drop_date_time && (
                          <span className="text-danger">
                            {errors.drop_date_time}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Number of Persons
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="no_of_persons"
                          value={formData.no_of_persons}
                          onChange={handleChange}
                        />
                        {errors.no_of_persons && (
                          <span className="text-danger">
                            {errors.no_of_persons}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Pick-up Address
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="pickup_address"
                          value={formData.pickup_address}
                          onChange={handleChange}
                        />
                        {errors.pickup_address && (
                          <span className="text-danger">
                            {errors.pickup_address}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Drop-off Address
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="drop_address"
                          value={formData.drop_address}
                          onChange={handleChange}
                        />
                        {errors.drop_address && (
                          <span className="text-danger">
                            {errors.drop_address}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Service Type
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="service_type"
                          value={formData.service_type}
                          onChange={handleChange}
                        >
                          <option value="One Way">One Way</option>
                          <option value="Hourly Service">Hourly Service</option>
                          <option value="Long Term">Long Term</option>
                        </select>
                        {errors.service_type && (
                          <span className="text-danger">
                            {errors.service_type}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="customer_name"
                          value={formData.customer_name}
                          onChange={handleChange}
                        />
                        {errors.customer_name && (
                          <span className="text-danger">
                            {errors.customer_name}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Telephone Number
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="customer_phone"
                          value={formData.customer_phone}
                          onChange={handleChange}
                        />
                        {errors.customer_phone && (
                          <span className="text-danger">
                            {errors.customer_phone}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="customer_email"
                          value={formData.customer_email}
                          onChange={handleChange}
                        />
                        {errors.customer_email && (
                          <span className="text-danger">
                            {errors.customer_email}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Payment Method<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="payment_method"
                          value={formData.payment_method}
                          onChange={handleChange}
                        >
                          <option value="cash">Cash</option>
                          <option value="card">Card</option>
                          <option value="paypal">Paypal</option>
                        </select>
                        {errors.payment_method && (
                          <span className="text-danger">
                            {errors.payment_method}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Additional Services
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="additional_services"
                            value="child seats"
                            onChange={handleChange}
                          />
                          <label className="form-check-label">
                            Child Seats
                          </label>
                        </div>
                        {formData.additional_services.includes(
                          "child seats"
                        ) && (
                          <>
                            <div className="input-block">
                              <label className="form-label">
                                Number of Children
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="child_count"
                                value={formData.child_count}
                                onChange={handleChange}
                                max="3"
                                min="1"
                              />
                              {errors.child_count && (
                                <span className="text-danger">
                                  {errors.child_count}
                                </span>
                              )}
                            </div>
                            <div className="input-block">
                              <label className="form-label">
                                Ages of Children
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="child_ages"
                                value={formData.child_ages}
                                onChange={handleChange}
                              />
                              {errors.child_ages && (
                                <span className="text-danger">
                                  {errors.child_ages}
                                </span>
                              )}
                            </div>
                          </>
                        )}
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="additional_services"
                            value="vip service"
                            onChange={handleChange}
                          />
                          <label className="form-check-label">
                            VIP Service at the airport (Geneva/ Zurich)
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="additional_services"
                            value="extra luggage vehicle"
                            onChange={handleChange}
                          />
                          <label className="form-check-label">
                            Extra Luggage Vehicle
                          </label>
                        </div>
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Additional Information
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="additional_information"
                          value={formData.additional_information}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-block">
                        <label className="form-label">
                          Pick-up Date & Time
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          name="pickup_date_time"   
                          value={formData.pickup_date_time}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.pickup_date_time && (
                          <span className="text-danger">
                            {errors.pickup_date_time}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Drop-off Date & Time
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          name="drop_date_time"
                          value={formData.drop_date_time}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.drop_date_time && (
                          <span className="text-danger">
                            {errors.drop_date_time}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Pick-up Location<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="pickup_address"
                          value={formData.pickup_address}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.pickup_address && (
                          <span className="text-danger">
                            {errors.pickup_address}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Drop-off Location
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="drop_address"
                          value={formData.drop_address}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.drop_address && (
                          <span className="text-danger">
                            {errors.drop_address}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="customer_name"
                          value={formData.customer_name}
                          onChange={handleChange}
                        />
                        {errors.customer_name && (
                          <span className="text-danger">
                            {errors.customer_name}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Telephone Number<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="customer_phone"
                          value={formData.customer_phone}
                          onChange={handleChange}
                        />
                        {errors.customer_phone && (
                          <span className="text-danger">
                            {errors.customer_phone}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="customer_email"
                          value={formData.customer_email}
                          onChange={handleChange}
                        />
                        {errors.customer_email && (
                          <span className="text-danger">
                            {errors.customer_email}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Payment Method<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="payment_method"
                          value={formData.payment_method}
                          onChange={handleChange}
                        >
                          <option value="cash">Cash</option>
                          <option value="card">Card</option>
                        </select>
                        {errors.payment_method && (
                          <span className="text-danger">
                            {errors.payment_method}
                          </span>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Additional Services
                          <span className="text-danger">*</span>
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="additional_services"
                            value="child seats"
                            onChange={handleChange}
                          />
                          <label className="form-check-label">
                            Child Seats
                          </label>
                        </div>
                        {formData.additional_services.includes(
                          "child seats"
                        ) && (
                          <>
                            <div className="input-block">
                              <label className="form-label">
                                Number of Children
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="child_count"
                                value={formData.child_count}
                                onChange={handleChange}
                                max="3"
                                min="1"
                              />
                              {errors.child_count && (
                                <span className="text-danger">
                                  {errors.child_count}
                                </span>
                              )}
                            </div>
                            <div className="input-block">
                              <label className="form-label">
                                Ages of Children
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="child_ages"
                                value={formData.child_ages}
                                onChange={handleChange}
                              />
                              {errors.child_ages && (
                                <span className="text-danger">
                                  {errors.child_ages}
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="input-block">
                        <label className="form-label">
                          Additional Information
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="additional_information"
                          value={formData.additional_information}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}
                  <button
                    type="submit"
                    className="btn btn-outline-light w-100 btn-size mt-1"
                  >
                    {loading ? "Sending..." : "Send Quotation"}
                  </button>
                  <div className="text-center dont-have">
                    Select Another Vehicle.{" "}
                    <Link
                      to={`${route.listinggrid}?category=limousine`}
                      onClick={() => {
                        window.location.href = route.home;
                        window.location.reload();
                      }}
                    >
                      Back
                    </Link>
                  </div>
                </form>
                {error && <span className="text-danger"> {error}</span>}
                <br />
                {showSuccessMessage &&
                  !error &&
                  booking?.data?.status === "pending" && (
                    <h4
                      className="success-text text-center"
                      style={{ color: "green" }}
                    >
                      request sent successfully. we will contact you soon.
                    </h4>
                  )}
              </div>
            </div>
          </div>
        </div>
        <footer className="log-footer">
            <div className="copyright">
              <div className="copyright-text">
                <p>© 2024 Eleego. All Rights Reserved.</p>
              </div>
            </div>
        </footer>
      </div>
    </div>
  );
};

export default ContactOwner;
