import axios from 'axios';
import { AppDispatch } from "./store";

export const FETCH_VEHICLES_REQUEST = 'FETCH_VEHICLES_REQUEST';
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS';
export const FETCH_VEHICLES_FAILURE = 'FETCH_VEHICLES_FAILURE';

export const FETCH_VEHICLE_BY_ID_REQUEST = 'FETCH_VEHICLE_BY_ID_REQUEST';
export const FETCH_VEHICLE_BY_ID_SUCCESS = 'FETCH_VEHICLE_BY_ID_SUCCESS';
export const FETCH_VEHICLE_BY_ID_FAILURE = 'FETCH_VEHICLE_BY_ID_FAILURE';

export const header_data = () => ({ type: "HEADER_DATA" });
export const set_header_data = (payload: any) => ({
  type: "HEADER_DATA",
  payload,
});
export const toggleSidebar = () => ({ type: "TOOGLE_SIDEBAR_DATA" });
export const set_toggleSidebar_data = (payload: boolean) => ({
  type: "TOOGLE_SIDEBAR_DATA",
  payload,
});

export const set_listingsdata = (payload: any) => ({
  type: "ListingsData",
  payload,
});
export const set_listing_list_data = (payload: any) => ({
  type: "Listings_List_Data1",
  payload,
});
export const set_pricing_data = (payload: any) => ({
  type: "Pricing_Data",
  payload,
});

export const set_our_teamdata = (payload: any) => ({
  type: "Our_team_data",
  payload,
});

export const set_testimonial_data = (payload: any) => ({
  type: "testimonial_data",
  payload,
});

export const set_contact_data = (payload: any) => ({
  type: "contact_data",
  payload,
});
export const set_userwallet_data = (payload: any) => ({
  type: "userwallet_data",
  payload,
});
export const set_userpayment_data = (payload: any) => ({
  type: "userpayment_data",
  payload,
});

export const fetchVehicles = (type: any) => async (dispatch: AppDispatch) => {
  dispatch({ type: FETCH_VEHICLES_REQUEST });
  try {
    const response = await axios.get(`https://admin.eleego.ch/api/v1/vehicles/${type}`);
    dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: response.data });
  } catch (error: any) {
    dispatch({ type: FETCH_VEHICLES_FAILURE, payload: error.message });
  }
};

export const fetchVehicleById = (id: any) => async (dispatch: AppDispatch) => {
  dispatch({ type: FETCH_VEHICLE_BY_ID_REQUEST });
  try {
    const response = await axios.get(`https://admin.eleego.ch/api/v1/vehicle/${id}`);
    dispatch({ type: FETCH_VEHICLE_BY_ID_SUCCESS, payload: response.data });
  } catch (error: any) {
    dispatch({ type: FETCH_VEHICLE_BY_ID_FAILURE, payload: error.message });
  }
};

export const SUBMIT_LIMOUSINE_BOOKING_REQUEST = 'SUBMIT_LIMOUSINE_BOOKING_REQUEST';
export const SUBMIT_LIMOUSINE_BOOKING_SUCCESS = 'SUBMIT_LIMOUSINE_BOOKING_SUCCESS';
export const SUBMIT_LIMOUSINE_BOOKING_FAILURE = 'SUBMIT_LIMOUSINE_BOOKING_FAILURE';

export const submitLimousineBooking = (bookingDetails: any) => async (dispatch: AppDispatch) => {
  dispatch({ type: SUBMIT_LIMOUSINE_BOOKING_REQUEST });
  try {
    const response = await axios.post('https://admin.eleego.ch/api/v1/confirm-booking', bookingDetails, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: SUBMIT_LIMOUSINE_BOOKING_SUCCESS, payload: response.data });
  } catch (error: any) {
    let errorMessage = error.message;
    if (error.response && error.response.data) {
      console.log(error.response)
      errorMessage = error.response.data.errors || errorMessage;
    }
    dispatch({ type: SUBMIT_LIMOUSINE_BOOKING_FAILURE, payload: errorMessage });
  }
};