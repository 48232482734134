import React from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import { CornerDownLeft } from "react-feather";
import { all_routes } from "../router/all_routes";

const route = all_routes;
const SignUp = () => {

  

  return (
    <div>
      <div className="main-wrapper login-body">
        {/* Header */}
        <header className="log-header">
          <Link to={route.home}>
            <ImageWithBasePath
              className="img-fluid logo-dark"
              src="assets/img/logo.svg"
              alt="Logo"
            />
          </Link>
        </header>
        {/* /Header */}
        <div className="login-wrapper">
          <div className="loginbox">
            <div className="login-auth">
              <div className="login-auth-wrap">
                <div className="sign-group">
                  <Link to={route.home} className="btn sign-up">
                    <span>
                      <CornerDownLeft />
                    </span>{" "}
                    Back To Home
                  </Link>
                </div>
                <h1>Booking Details</h1>
                <p className="account-subtitle">
                  Fil out the following details to recive a quotation for our premier Limousine Service.
                </p>
                <form >
                  <div className="input-block">
                    <label className="form-label">
                      Pick-up Date & Time<span className="text-danger">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Drop-off Date & Time<span className="text-danger">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="input-block">
                    <input type="checkbox" name="" id="" />&nbsp;
                    <label className="form-lable">On Way&nbsp;<span className="text-danger">*</span></label>&nbsp;&nbsp;
                    <input type="checkbox" name="" id="" />&nbsp;
                    <label className="form-lable">Hourly Service&nbsp;<span className="text-danger">*</span></label>&nbsp;&nbsp;
                    <input type="checkbox" name="" id="" />&nbsp;
                    <label className="form-lable">Long Time Service&nbsp;<span className="text-danger">*</span></label>&nbsp;&nbsp;
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Number of Persons<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Telephone Number<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Payment Method<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Please Check Additional Service Required<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Additional Information<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <Link
                    to={route.home}
                    className="btn btn-outline-light w-100 btn-size mt-1"
                  >
                    Send Quotation
                  </Link>
                  
                  <div className="text-center dont-have">
                    Select Another Vehicle.{" "}
                    <Link to={route.home}>Back</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="log-footer">
          <div className="container-fluid">
            {/* Copyright */}
            <div className="copyright">
              <div className="copyright-text">
                <p>© 2024 Eleego. All Rights Reserved.</p>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </footer>
        {/* /Footer */}
      </div>
    </div>
  );
};

export default SignUp;
