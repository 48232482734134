import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../common/Breadcrumbs";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { useLocation } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import { AppDispatch, RootState } from "../../core/data/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchVehicleById } from "../../core/data/redux/action";
import ImageWithURL from "../../core/data/img/ImageWithURL";
import ContactOwner from "../authentication/contactowner";

const listingDetails = () => {
  const dispatch: AppDispatch = useDispatch();

  // Local state for vehicles
  const [vehicleDetail, setLocalVehicle] = useState<any>([]);
  console.log("vehicleDetail", vehicleDetail);
  // Fetch vehicles from Redux store
  const vehicle = useSelector((state: RootState) => state.vehicle);

  const location = useLocation();

  const getID = () => {
    const params = new URLSearchParams(location.search);
    return params.get("id");
  };

  const id = getID();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [vehicleAvailability, setVehicleAvailability] = useState<boolean>("");

  useEffect(() => {
    if (startDate == "" || endDate == "") {
      console.log("ddddddddddd", startDate, endDate);
      setVehicleAvailability(false);
    }
  }, [startDate, endDate]);

  const checkAvailability = async (vehicleDetail, startDate, endDate) => {
    if (!startDate || !endDate) {
      // Validate startDate and endDate
      alert("Please select both Start Date and End Date.");
      return;
    }

    const apiUrl = `https://admin.eleego.ch/api/v1/vehicles-availability`;
    const params = new URLSearchParams({
      start_date: startDate,
      end_date: endDate,
      vehicle_id: vehicleDetail.id, // Assuming vehicleDetail has an id property
    });

    console.log("params", params.toString());
    const url = `${apiUrl}?${params.toString()}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json(); // Convert response to JSON

      // Log the response data or perform further actions
      setVehicleAvailability(responseData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch vehicles when component mounts
  useEffect(() => {
    dispatch(fetchVehicleById(id || 1));
  }, [dispatch, location]);

  // Synchronize local state with Redux state
  useEffect(() => {
    setLocalVehicle(vehicle.data);
  }, [vehicle]);

  const bigImgSliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);

  const bigImgSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <i className="fa-chevron-right" />,
    prevArrow: <i className="fa-chevron-left" />,
  };

  useEffect(() => {
    if (bigImgSliderRef.current && thumbnailSliderRef.current) {
      bigImgSliderRef.current.slickGoTo(0);
      thumbnailSliderRef.current.slickGoTo(0);
    }
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  if (!vehicleDetail) {
    return <div>Loading...</div>; // Or some loading indicator
  }
  return (
    <div className="main-wrapper">
      <Breadcrumbs title={vehicleDetail.name} subtitle="Listings" />
      <section className="product-detail-head">
        <div className="container">
          <div className="detail-page-head">
            <div className="detail-headings">
              <div className="star-rated">
                <div className="list-rating">
                  <span className="year">2023</span>
                </div>
                <div className="camaro-info">
                  <h3>{vehicleDetail.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section product-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="detail-product">
                <Slider className="detail-bigimg" {...bigImgSettings}>
                  <div className="product-img">
                    <ImageWithURL src={vehicleDetail.image_url} alt="Slider" />
                  </div>
                  <div className="product-img">
                    <ImageWithURL src={vehicleDetail.image_url} alt="Slider" />
                  </div>
                  <div className="product-img">
                    <ImageWithURL src={vehicleDetail.image_url} alt="Slider" />
                  </div>
                  <div className="product-img">
                    <ImageWithURL src={vehicleDetail.image_url} alt="Slider" />
                  </div>
                  <div className="product-img">
                    <ImageWithURL src={vehicleDetail.image_url} alt="Slider" />
                  </div>
                </Slider>
                <h3 className="text-primary ml-4">Per Day Amount - {vehicleDetail.per_day_amount} CHF</h3>
              </div>

              <div className="review-sec specification-card ">
                <div className="review-header">
                  <h4>Specifications</h4>
                </div>
                <div className="card-body">
                  <div className="lisiting-featues">
                    <div className="row">
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src="assets/img/specification/specification-icon-2.svg"
                            alt=""
                          />
                        </div>
                        <div className="featues-info">
                          <span>Make </span>
                          <h6> {vehicleDetail.brand_name}</h6>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src="assets/img/specification/specification-icon-3.svg"
                            alt=""
                          />
                        </div>
                        <div className="featues-info">
                          <span>Transmission </span>
                          <h6> {vehicleDetail.transmission}</h6>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src="assets/img/specification/specification-icon-4.svg"
                            alt=""
                          />
                        </div>
                        <div className="featues-info">
                          <span>Fuel Type </span>
                          <h6> {vehicleDetail.fuel_type}</h6>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src="assets/img/specification/specification-icon-8.svg"
                            alt=""
                          />
                        </div>
                        <div className="featues-info">
                          <span>AC </span>
                          <h6> Air Condition</h6>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src="assets/img/specification/specification-icon-10.svg"
                            alt=""
                          />
                        </div>
                        <div className="featues-info">
                          <span>Door </span>
                          <h6> {vehicleDetail.doors} Doors</h6>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src="assets/img/specification/specification-icon-1.svg"
                            alt=""
                          />
                        </div>
                        <div className="featues-info">
                          <span>Baggages</span>
                          <h6> {vehicleDetail.baggages}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="review-sec extra-service mb-0">
                <div className="review-header">
                  <h4>Description of the Vehicle</h4>
                </div>
                <div className="description-list">
                  {vehicleDetail.description}
                </div>
              </div>
            </div>
            <div className="col-lg-4 theiaStickySidebar">
              <div className="stickysidebar">
                <div className="review-sec mt-0">
                  <div className="review-header">
                    <h4>Check Availability</h4>
                  </div>
                  <div>
                    <form>
                      <ul>
                        <li className="column-group-main">
                          <div className="input-block m-0">
                            <label>Pickup Date & Time</label>
                          </div>
                          <div className="input-block me-2">
                            <div className="group-img">
                              <input
                                type="datetime-local"
                                className="form-control datetimepicker"
                                placeholder="04/11/2023"
                                name="startDate"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </li>
                        <li className="column-group-main">
                          <div className="input-block m-0">
                            <label>Return Date & Time</label>
                          </div>
                          <div className="input-block me-2">
                            <div className="group-img">
                              <input
                                type="datetime-local"
                                className="form-control datetimepicker"
                                placeholder="04/11/2023"
                                name="endDate"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                            </div>
                          </div>
                        </li>
                        <li className="column-group-last">
                          <div className="input-block mb-0">
                            <div className="search-btn">
                              <button
                                className={`btn btn-primary check-available w-100 ${!startDate || !endDate ? "disabled-btn-check" : ""}`}
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#pages_edit"
                                onClick={() =>
                                  checkAvailability(
                                    vehicleDetail,
                                    startDate,
                                    endDate
                                  )
                                }
                                disabled={!startDate || !endDate}
                              >
                                {" "}
                                Check Availability
                              </button>
                              {(!startDate || !endDate) && (
                                <p className="text-danger mt-2">
                                  Please ensure both Start Date and End Date are
                                  selected to check availability.
                                </p>
                              )}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal custom-modal fade check-availability-modal"
        id="pages_edit"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <div className="form-header text-start mb-0">
                <h4 className="mb-0 text-dark fw-bold">Availability Details</h4>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="align-center" aria-hidden="true">
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {vehicleAvailability == true ? (
                  <>
                    <div>
                      <div className="available-for-ride">
                        <p>
                          <i className="fa-regular fa-circle-check" />
                          {vehicleDetail.name} is available for a ride
                        </p>
                      </div>
                      <div>
                        <ContactOwner
                          vehicleData={vehicleDetail}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="not-available-for-ride">
                    <p>
                      <i className="fa-regular fa-circle-xmark" />
                      {vehicleDetail.name} is not available for a ride
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default listingDetails;
