import initialState from "./initial.values";
import { AnyAction } from "redux";

import {
  FETCH_VEHICLES_REQUEST,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLES_FAILURE,
  FETCH_VEHICLE_BY_ID_REQUEST,
  FETCH_VEHICLE_BY_ID_SUCCESS,
  FETCH_VEHICLE_BY_ID_FAILURE,
  SUBMIT_LIMOUSINE_BOOKING_REQUEST,
  SUBMIT_LIMOUSINE_BOOKING_SUCCESS,
  SUBMIT_LIMOUSINE_BOOKING_FAILURE,
} from './action';

const rootReducer :any = (state = initialState, action: AnyAction ) => {
  const { type, payload } = action;
  switch (type) {
    case "HEADER_DATA":
      return { ...state, header_data: payload };
    case "ListingsData":
      return { ...state, listing_grid: payload };
    case "Listings_List_Data":
      return { ...state, listing_list:payload };
    case "Pricing_Data":
      return { ...state, pricingdata:payload };
    case "Our_team_data":
      return { ...state, ourteamdata: payload };
    case "testimonial_data":
      return { ...state, testimonialdata: payload };
    case "contact_data":
      return { ...state, contactdata: payload };
    case "Userwallet_data":
      return { ...state, userwallet_data: payload };
    case "UserPayment_data":
      return { ...state, userpayment_data: payload };
    case FETCH_VEHICLES_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_VEHICLES_SUCCESS:
      return { ...state, loading: false, vehicles: action.payload };
    case FETCH_VEHICLES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_VEHICLE_BY_ID_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_VEHICLE_BY_ID_SUCCESS:
      return { ...state, loading: false, vehicle: action.payload };
    case FETCH_VEHICLE_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SUBMIT_LIMOUSINE_BOOKING_REQUEST:
      return { ...state, loading: true, error: null };
    case SUBMIT_LIMOUSINE_BOOKING_SUCCESS:
      return { ...state, loading: false, booking: payload };
    case SUBMIT_LIMOUSINE_BOOKING_FAILURE:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};

export default rootReducer;