import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { testimonialsData } from "../../core/data/json/testimonials_data";
import { all_routes } from "../router/all_routes";

const Home = () => {
  const routes = all_routes;
  const testimonials = testimonialsData;
  const [isScrolling, setIsScrolling] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipe: !isScrolling, // Disable swipe if scrolling
  };

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });

    let touchStartY = 0;

    const handleTouchStart = (event) => {
      touchStartY = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
      const touchCurrentY = event.touches[0].clientY;
      const touchDiffY = touchCurrentY - touchStartY;

      if (Math.abs(touchDiffY) > 10) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    const handleTouchEnd = () => {
      setIsScrolling(false);
    };

    const carousel = document.querySelector(".carousel");
    if (carousel) {
      carousel.addEventListener("touchstart", handleTouchStart);
      carousel.addEventListener("touchmove", handleTouchMove);
      carousel.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (carousel) {
        carousel.removeEventListener("touchstart", handleTouchStart);
        carousel.removeEventListener("touchmove", handleTouchMove);
        carousel.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  return (
    <>
      {/* Banner */}
      <div>
        <Carousel
          showArrows={false}
          autoPlay={true}
          interval={5000}
          infiniteLoop
          emulateTouch={true}
          showStatus={false}
          swipeable={!isScrolling}
        >
          <div className="imageOne">
            <div className="container">
              <div className="home-banner">
                <div className="row align-items-center">
                  <div className="col-lg-6" data-aos="fade-down">
                    <h1 className="text-white">
                      Discover luxury & elegance <br />
                    </h1>
                    <h3 className="text-primary">with our premier Limousine and rental service</h3>
                    <p className="text-white">
                      Welcome to Eleego Limousine and Rental Service, where
                      luxury meets convenience. Whether you are looking for a
                      luxurious limousine for a special occasion or a rental car
                      for travel, Eleego has you covered.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="imageTwo">
            <div className="container">
              <div className="home-banner">
                <div className="row align-items-center">
                  <div className="col-lg-6" data-aos="fade-down">
                    <h1 className="text-white">
                      Discover luxury & elegance <br />
                    </h1>
                    <h3 className="text-primary">with our premier Limousine and rental service</h3>
                    <p className="text-white">
                      Welcome to Eleego Limousine and Rental Service, where
                      luxury meets convenience. Whether you are looking for a
                      luxurious limousine for a special occasion or a rental car
                      for travel, Eleego has you covered.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="imageThree">
            <div className="container">
              <div className="home-banner">
                <div className="row align-items-center">
                  <div className="col-lg-6" data-aos="fade-down">
                    <h1 className="text-white">
                      Discover luxury & elegance <br />
                    </h1>
                    <h3 className="text-primary">with our premier Limousine and rental service</h3>
                    <p className="text-white">
                      Welcome to Eleego Limousine and Rental Service, where
                      luxury meets convenience. Whether you are looking for a
                      luxurious limousine for a special occasion or a rental car
                      for travel, Eleego has you covered.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      {/* /Banner */}

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="bloginner-img">
              <Link to={`${routes.listinggrid}?category=rental`}>
                <ImageWithBasePath
                  src="assets/img/carrental.png"
                  className="img-fluid"
                  alt="Blog"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="bloginner-img">
              <Link to={`${routes.listinggrid}?category=limousine`}>
                <ImageWithBasePath
                  src="assets/img/limousine.png"
                  className="img-fluid"
                  alt="Blog"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* services */}
      <section className="section services">
        <div className="service-right">
          <ImageWithBasePath
            src="assets/img/bg/service-right.svg"
            className="img-fluid"
            alt="services right"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>The booking process</h2>
            <p>Booking a car with us is quick and easy</p>
          </div>
          {/* /Heading title */}
          <div className="services-work">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-secondary">
                    <ImageWithBasePath
                      className="icon-img bg-secondary"
                      src="assets/img/icons/services-icon-01.svg"
                      alt="Choose Locations"
                    />
                  </div>
                  <div className="services-content">
                    <h3>1. Choose a vehicle</h3>
                    <p>
                      Browse our selection of cars and pick the one that suits
                      your needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-warning">
                    <ImageWithBasePath
                      className="icon-img bg-warning"
                      src="assets/img/icons/services-icon-02.svg"
                      alt="Choose Locations"
                    />
                  </div>
                  <div className="services-content">
                    <h3>2. Validate details</h3>
                    <p>
                      Enter your personal and rental details to confirm
                      everything is correct.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12" data-aos="fade-down">
                <div className="services-group">
                  <div className="services-icon border-dark">
                    <ImageWithBasePath
                      className="icon-img bg-dark"
                      src="assets/img/icons/services-icon-03.svg"
                      alt="Choose Locations"
                    />
                  </div>
                  <div className="services-content">
                    <h3>3. Book your vehicle</h3>
                    <p>
                      Complete your booking and get ready to enjoy your ride!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /services */}
      {/* Popular Services */}

      {/* /Facts By The Numbers */}

      {/* Why Choose Us */}
      <section className="section why-choose popular-explore">
        <div className="choose-left">
          <ImageWithBasePath
            src="assets/img/bg/choose-left.png"
            className="img-fluid"
            alt="Why Choose Us"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Why Choose Us</h2>
            <p>
              With Reliable cars, great prices, and top-notch service your
              perfect ride awaits!
            </p>
          </div>
          {/* /Heading title */}
          <div className="why-choose-group">
            <div className="row">
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-black">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-selection.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Easy Booking Process</h4> {/*&amp;*/}
                      <p>
                        Our simple and straightforward booking process allows
                        you to reserve your car quickly and efficiently, saving
                        you time and effort.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-secondary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-crown.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Flexible Rental Options</h4>
                      <p>
                        Whether you need a car for a day, a week, or longer, we
                        offer flexible rental periods to suit your schedule.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-primary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-user-check.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Excellent Customer Service</h4>
                      <p>
                        Our friendly and knowledgeable team is here to assist
                        you every step of the way, ensuring a smooth and
                        hassle-free rental experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Why Choose Us */}
      {/* About us Testimonials */}
      <section className="section about-testimonial testimonials-section">
        <div className="container">
          <div className="section-heading" data-aos="fade-down">
            <h2 className="title text-white">Customer Testimonials</h2>
            <p className="description text-white">
              Discover why our customers love us! Read what others have to say
              about their experiences with our car rental services.
            </p>
          </div>
          <div className="owl-carousel about-testimonials testimonial-group mb-0 owl-theme">
            <Slider {...settings}>
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="testimonial-item d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <div className="quotes-head" />
                      <div className="review-box">
                        <div className="review-details">
                          <h6>{testimonial.name}</h6>
                        </div>
                      </div>
                      <p>{testimonial.review}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      {/* About us Testimonials */}
      {/* FAQ  */}
      <section className="section faq-section bg-light-primary">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Frequently Asked Questions </h2>
            <p>
              Find answers to common questions about our car rental services,
              policies, and more to make your experience with us seamless and
              straightforward.
            </p>
          </div>
          {/* /Heading title */}
          <div className="faq-info">
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqOne"
                  aria-expanded="false"
                >
                  What is about eleego?
                </Link>
              </h4>
              <div id="faqOne" className="card-collapse collapse">
                <p>
                  Located in Geneva, Switzerland, and established in 2023, our
                  company has quickly become a premier choice for customers
                  seeking top-quality transportation solutions at an affordable
                  rate. At Eleego, we are dedicated to giving our customers a
                  luxury, convenient, and unforgettable transportation
                  experience by ensuring our guests make memories that will last
                  for a lifetime.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqTwo"
                  aria-expanded="false"
                >
                  In which areas do you operate?
                </Link>
              </h4>
              <div id="faqTwo" className="card-collapse collapse">
                <p>
                  At Eleego Limousine and Rental Service, we offer a range of
                  premium transportation solutions tailored to meet your needs:
                </p>
                <p>
                  <b>Limousine Services:</b> Our luxurious limousine services
                  are perfect for special occasions, business trips, airport
                  transfers, and city tours. Enjoy the comfort and elegance of
                  our well-maintained fleet, driven by professional chauffeurs
                  dedicated to providing you with a first-class experience
                </p>
                <p>
                  <b>Car Rentals:</b> For those who prefer to drive themselves,
                  we offer a selection of high-quality rental cars. From sleek
                  sedans to spacious SUVs, our rental fleet is designed to cater
                  to your specific travel requirements. Whether you&apos;re
                  exploring Geneva or embarking on a longer journey, our rental
                  cars provide the freedom and flexibility you need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /FAQ */}
      {/* Pricing Plan */}
      {/* <section className="pricing-section pricing-page pricing-section-bottom">
        <div className="container">
          <div className="user-app-group">
            <div className="app-left">
              <ImageWithBasePath
                src="assets/img/bg/app-left.png"
                className="img-fluid"
                alt="App Available"
              />
            </div>
            <div className="app-right">
              <ImageWithBasePath
                src="assets/img/bg/app-right.png"
                className="img-fluid"
                alt="App Available"
              />
            </div>
            <div className="row">
              <div className="col-lg-7">
                <div className="userapp-heading">
                  <h2 data-aos="fade-down">
                    Dreamsrental User Friendly App Available
                  </h2>
                  <p data-aos="fade-down">
                    Appropriately monetize one-to-one interfaces rather than
                    cutting-edge Competently disinte rmediate backward.
                  </p>
                  <div className="download-btn">
                    <div className="app-avilable" data-aos="fade-down">
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/play-store.svg"
                          alt="PlayStore"
                        />
                      </Link>
                    </div>
                    <div className="app-avilable" data-aos="fade-down">
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/apple.svg"
                          alt="AppStore"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 d-none d-lg-block">
                <div className="car-holder">
                  <ImageWithBasePath
                    className="app-car img-fluid"
                    src="assets/img/app-car.png"
                    alt="App Available"
                    data-aos="fade-down"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* /Pricing Plan */}
    </>
  );
};

export default Home;
