import React, { useEffect, useState } from "react";
import Breadcrumbs from "../common/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";
// import Select from "react-select";
import { all_routes } from "../router/all_routes";
// import ListingSidebar from "./listingsidebar";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { useDispatch, useSelector } from "react-redux";
// import { listgrids } from "../../core/data/interface/interface";
import Aos from "aos";
import { AppDispatch, RootState } from "../../core/data/redux/store";
import { fetchVehicles } from "../../core/data/redux/action";
import ImageWithURL from "../../core/data/img/ImageWithURL";

const ListingGrid = () => {
  // const data = useSelector((state: listgrids) => state.listing_grid);

  const dispatch: AppDispatch = useDispatch();

  // Local state for vehicles
  const [localVehicles, setLocalVehicles] = useState<any[]>([]);
  console.log("localVehicles", localVehicles);
  // Fetch vehicles from Redux store
  const vehicles = useSelector((state: RootState) => state.vehicles);
  const location = useLocation();

  const getCategory = () => {
    const params = new URLSearchParams(location.search);
    return params.get("category");
  };

  const category = getCategory();

  // Fetch vehicles when component mounts
  useEffect(() => {
    dispatch(fetchVehicles(category));
  }, [dispatch, location]);

  // Synchronize local state with Redux state
  useEffect(() => {
    setLocalVehicles(vehicles.data);
  }, [vehicles]);

  const routes = all_routes;
  // const options = [
  //   { value: 5, label: "5" },
  //   { value: 10, label: "10" },
  //   { value: 15, label: "15" },
  //   { value: 20, label: "20" },
  // ];
  // const optionsTwo = [
  //   { value: 1, label: "Low to High" },
  //   { value: 2, label: "High to Low" },
  // ];

  // const optionsThree = [
  //   { value: "popular", label: "Popular" },
  //   { value: "toyota", label: "Toyota Camry SE 350" },
  //   { value: "audi", label: "Audi A3 2019 new" },
  //   { value: "ferrari", label: "Ferrari 458 MM Speciale" },
  //   { value: "chevrolet", label: "Chevrolet Camaro" },
  //   { value: "acura", label: "Acura Sport Version" },
  // ];
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  return (
    <div className="main-wrapper">
      <Breadcrumbs title="Car Listings" subtitle="Listings" />
      {/* <div className="sortby-sec">
        <div className="container">
          <div className="sorting-div">
            <div className="row d-flex align-items-center">
              <div className="col-xl-4 col-lg-3 col-sm-12 col-12">
                <div className="count-search">
                  <p>
                    Showing <span>1-8</span> of 10 Results
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 col-sm-12 col-12">
                <div className="product-filter-group">
                  <div className="sortbyset">
                    <span className="sortbytitle">Show : </span>
                    <div className="sorting-select select-one">
                      <Select
                        className="select w-100"
                        options={options}
                        placeholder="5"
                        isSearchable={false}
                      />
                    </div>
                    <div className="sorting-select select-two">
                      <Select
                        className="select w-100"
                        options={optionsTwo}
                        placeholder="Low to High"
                        isSearchable={false}
                      />
                    </div>
                    <div className="sorting-select select-three">
                      <Select
                        className="select"
                        options={optionsThree}
                        placeholder="Popular"
                        aria-labelledby="carDropdownLabel"
                        isSearchable={false}
                      />
                    </div>
                  </div>
                  <div className="grid-listview">
                    <ul>
                      <li>
                        <Link to={routes.listinggrid} className="active">
                          <i className="feather icon-grid"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.listinglist}>
                          <i className="feather icon-list"> </i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <section className="section car-listing">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3 col-12 theiaStickySidebar">
              <div className="stickybar">
                <ListingSidebar />
              </div>
            </div> */}
            <div className="col-lg-12 justify-content-center text-center align-items-center">
              <div className="row">
                {/* col */}
                {localVehicles && localVehicles.length ? (
                  localVehicles.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="col-xl-3 col-lg-6 col-md-6 col-12"
                    >
                      <div className="listing-item">
                        <div className="listing-img">
                          <Link to={`${routes.listingdetails}?id=${item.id}`}>
                            <ImageWithURL
                              src={item.image_url}
                              className="img-fluid"
                              alt={item.image_url}
                            />
                          </Link>
                          <div className="fav-item">
                            <span className="featured-text">
                              {item.brand_name}
                            </span>
                          </div>
                        </div>
                        <div className="listing-content">
                          <div className="listing-features">
                            <h3 className="listing-title">
                              <Link
                                to={`${routes.listingdetails}?id=${item.id}`}
                              >
                                {item.name}
                              </Link>
                            </h3>
                          </div>
                          {item.category == "Rental" && (
                            <div>
                              <h5 className="listing-title text-primary">
                                {item.per_day_amount ? (
                                  <>
                                    Per Day Amount - {item.per_day_amount} CHF
                                  </>
                                ) : (
                                  <>
                                    Per Day Amount - Not Available
                                  </>
                                )}
                              </h5>
                            </div>
                          )}
                          <br></br>
                          <div className="listing-details-group">
                            <ul>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/lock-icon.svg"
                                    alt={item.features?.mileage}
                                  />
                                </span>
                                <p>{item.baggages}</p>
                              </li>
                              <li></li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-03.svg"
                                    alt={item.fuel_type}
                                  />
                                </span>
                                <p>{item.fuel_type}</p>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-04.svg"
                                    alt={item.transmission}
                                  />
                                </span>
                                <p>{item.transmission}</p>
                              </li>
                              <li>
                                {/* <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-05.svg"
                                  alt={item.features?.year}
                                />
                              </span>
                              <p>{item.features?.year}</p> */}
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-06.svg"
                                    alt="Persons"
                                  />
                                </span>
                                <p>{item.seats} Persons</p>
                              </li>
                            </ul>
                          </div>
                          {/* <div className="listing-location-details">
                          <div className="listing-price">
                            <span>
                              <i className="feather icon-map-pin" />
                            </span>
                            {item.location}
                          </div>
                          <div className="listing-price">
                            <h6>{item.price}</h6>
                          </div>
                        </div> */}
                          <div className="listing-button">
                            <Link
                              to={`${routes.listingdetails}?id=${item.id}`}
                              className="btn btn-order"
                            >
                              <span>
                                <i className="feather icon-calendar me-2" />
                              </span>
                              {item.category == "Rental"
                                ? "Rent Now"
                                : "Get a Quote"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <h2>Loading..</h2>
                  </div>
                )}
                {/* /col */}
                {/* col */}
              </div>
              {/*Pagination*/}
              {/* <div className="blog-pagination">
                <nav>
                  <ul className="pagination page-item justify-content-center">
                    <li className="previtem">
                      <Link className="page-link" to="#">
                        <i className="fa-solid fa-arrow-left"></i>
                        Prev
                      </Link>
                    </li>
                    <li className="justify-content-center pagination-center">
                      <div className="page-group">
                        <ul>
                          <li className="page-item">
                            <Link className="page-link" to="#">
                              1
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link className="active page-link" to="#">
                              2{" "}
                              <span className="visually-hidden">(current)</span>
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link className="page-link" to="#">
                              3
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link className="page-link" to="#">
                              4
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link className="page-link" to="#">
                              5
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nextlink">
                      <Link className="page-link" to="#">
                        Next{" "}
                        <i className="fas fa-regular fa-arrow-right ms-2"></i>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div> */}
              {/*/Pagination*/}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ListingGrid;
