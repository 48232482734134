import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";

const Faq = () => {
  const routes = all_routes;

  return (
    <>
      {/* Breadscrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">FAQ</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.home}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Pages</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    FAQ
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadscrumb */}
      <section className="section faq-section">
        <div className="container">
          <div className="faq-info">
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqOne"
                  aria-expanded="false"
                >
                  What is about eleego?
                </Link>
              </h4>
              <div id="faqOne" className="card-collapse collapse">
                <p>
                  Located in Geneva, Switzerland, and established in 2023, our
                  company has quickly become a premier choice for customers
                  seeking top-quality transportation solutions at an affordable
                  rate. At Eleego, we are dedicated to giving our customers a
                  luxury, convenient, and unforgettable transportation
                  experience by ensuring our guests make memories that will last
                  for a lifetime.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqTwo"
                  aria-expanded="false"
                >
                  In which areas do you operate?
                </Link>
              </h4>
              <div id="faqTwo" className="card-collapse collapse">
                <p>
                  At Eleego Limousine and Rental Service, we offer a range of
                  premium transportation solutions tailored to meet your needs:
                </p>
                <p>
                  <b>Limousine Services:</b> Our luxurious limousine services
                  are perfect for special occasions, business trips, airport
                  transfers, and city tours. Enjoy the comfort and elegance of
                  our well-maintained fleet, driven by professional chauffeurs
                  dedicated to providing you with a first-class experience
                </p>
                <p>
                  <b>Car Rentals:</b> For those who prefer to drive themselves,
                  we offer a selection of high-quality rental cars. From sleek
                  sedans to spacious SUVs, our rental fleet is designed to cater
                  to your specific travel requirements. Whether you&apos;re
                  exploring Geneva or embarking on a longer journey, our rental
                  cars provide the freedom and flexibility you need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
